
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 27.11.2014, 14:21:49
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/



.tooltip {
  font-size: @defaultFontSize;
  position: absolute;
  vertical-align: middle;
  z-index: 11;
  width: 252px; /*This dimenstion is used in determining left/right tooltip direction*/
  top: 50%;
  transform: translateY(-50%);
  cursor: default;
  line-height: normal;

  &:not(.left) {
    left: 100%;
  }

  &.left {
    right: 100%;
  }

  .tooltip-content {
    padding: 10px 15px;
    color: #000;
    background-color: @white;
    z-index: 12;
    .bordered();
    box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);

    >div {
      max-height: 300px;
    }

    li {
      padding: 5px 0;

      &.cuzk {
        padding-left: 23px;
        line-height: 16px;
        background-image: url(../img/cuzk.png);
        background-size: 16px 16px;
        background-repeat: no-repeat;
        background-position-y: 4px;
      }

      &.title {
        &:before {
          content: "\f022";
        }
      }

      &.map {
        &:before {
          content: "\f041";
        }
      }

      &.case {
        &:before {
          content: "\f016";
        }
      }

      &.ownership {
        &:before {
          content: "\f007";
        }
      }

      &.parcel {
        &:before {
          content: "\f151";
        }
      }

      &.process {
        &:before {
          content: "\f013";
        }
      }

      &.building {
        &:before {
          content: "\f0f7";
        }
      }

      &.map, &.title, &.case, &.ownership, &.parcel, &.building, &.process {
        line-height: 16px;
        vertical-align: bottom;
        margin-left: 22px;

        &:before {
          color: #0096dc;
          font-family: FontAwesome;
          font-size: 16px;
          display: inline-block;
          width: 16px;
          text-align: center;
          position: absolute;
          margin-left: -22px;
        }

      }
    }
    a:not(.button) {
      text-transform: none !important;
      color: @colLightBlue;

      &:hover {
        color: @colDimGray;
      }
    }

    .bordered-top {
      border-top-color: #000;
    }
  }
}

.tooltip-hover-area{
  .number{
    color: @colLightBlue;
  }

  &:hover{
    background-color: @colAzureBlue;

    .number{
      color: @white;
    }

    .icon-count {
      >i {
        color: @white;
      }
    }
  }
}

.tooltip--nowrap .tooltip-content {
  white-space: nowrap;
}

.tooltip-min .tooltip {
  transform: translateY(-50%) scale(0.7);
}
.tooltip-min .tooltip.tooltip-show {
  transition: all .15s cubic-bezier(.25,.8,.25,1);
  transition-duration: 0.15s;
  transform: translateY(-50%) scale(1);
}

.tooltip-min .tooltip {
  width: auto;
  margin-left: 10px;

  .tooltip-content {
    padding: 7px;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    top: 50%;
    transform: translateY(-50%);
  }

  &:not(.left) {
    /* this border color controlls the color of the triangle (what looks like the fill of the triangle) */
    &:after {
      left: -9px;
      border-color: transparent white transparent transparent;
      border-width: 7px 10px 7px 0;
    }

    /* this border color controlls the outside, thin border */
    &:before {
      left: -11px;
      border-color: transparent @grayBorder transparent transparent;
      border-width: 9px 12px 9px 0;
    }
  }

  &.left {
    &:after {
      right: -9px;
      border-color: transparent transparent transparent white;
      border-width: 7px 0px 7px 10px;
    }

    &:before {
      right: -11px;
      border-color: transparent transparent transparent @grayBorder;
      border-width: 9px 0 9px 12px;
    }
  }
}
