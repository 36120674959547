/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 11:02:55
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

@import "./variables-common.less";

#menu {
  z-index: 100;
  position: relative;;
  background-color: @SYMAPBlue;
  color: #ffffff;
  flex: 0 0 @menuBottomBarWidth;

  &.collapsed{
    flex: 0 0 50px;
  }

  .sections{

    &>ul {
      &>li {
        background: @SYMAPBlue;
        display: inline-block;
        width: 100%;

        .section-label{
          display: inline-block;
        }

        > a {
          width: 100%;
          height: 36px;
          align-items: center;
          display: inline-flex;
          color: #ffffff;
          text-transform: uppercase;
          text-decoration: none;

          i {
            min-width: 50px;
            &.fa-angle-down {
              display: none;

              &:before {
                color: white;
              }
            }

            &:before {
              display: block;
              text-align: center;
            }
          }
        }

        ul.collapsed{
          padding-left: 0px;
        }

        ul{
          padding-left: 4px;
          li{
          > a{
            text-transform: none;
          }
        }
        }

        &:hover, &.active, &.submenu-active {
          &> a {
            color: #FFFFFF !important;
            i:before {
              color: #FFFFFF !important;
            }
          }
        }

        &.collapsed{
          width: auto;
        }
      }
    }
  }

  .menu-item.collapsed .section-label{
    display: none;
  }

  .logo {
    line-height: 40px;
    font-size: 22px;
    font-weight: bold;
    display: flex;
    a {
      color: #FFFFFF;
    }

    .menu-indicator{
      display: inline-block;
      font-weight: normal;
      font-size: 20px;
      i{
        width: 50px;
        &:before {
          display: block;
          text-align: center;
        }
      }
    }
  }

  .cm-app-switcher-wrap {
    clear: both;
    position: absolute;
    bottom: 35px;
    height: 36px;
    width: 50px;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 34px;
    cursor: pointer;
    border-radius: 2px;
  }

  .cm-app-switcher {
    @circleRadius: 16px;
    .app-circle {
      position: absolute;
      top: 9px;
      background: @SYMAPBlue;
      width: @circleRadius;
      height: @circleRadius;
      border-color: @colAzureBlue;
      border-style: solid;
      border-width: 4px;
      border-radius: 100%;
      transition: all .15s cubic-bezier(.25,.8,.25,1);

      &.first{
        margin-left: ((50 - @circleRadius) / 2) - (10 / 2);
        z-index: 100;
      }
      &.second{
        margin-left: ((50 - @circleRadius) / 2) + (10 / 2);
        z-index: 99;
      }
    }

    &.active{
      .first{
        margin-left: ((50 - @circleRadius) / 2) - (10 / 2) - 3;
      }
      .second{
        margin-left: ((50 - @circleRadius) / 2) + (10 / 2) + 3;
      }
    }
  }

  .cm-app-switcher-menu{
    .bordered();
    display:flex;
    flex-flow:column;
    width: 170px;
    background: #FFFFFF;
    box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
    box-sizing: border-box;
    padding: 8px 0px;

    .application {
      color: #000000;
      display: block;
      line-height: 30px;
      font-size: 10px;
      height: 40px;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 4px;
      text-decoration: none;

      &:hover{
        background-color: @colHoverItem;
      }

      img {
        vertical-align: middle;

        &.logo-image {
          height: 27px;
        }

        &.logo-text {
          height: 13px;
        }

        &.logo-settings-image {
          height: 23px;
        }

        &.logo-settings-text {
          height: 16px;
        }
      }

      span{
        margin-left: 28px;
      }

      i {
        position: absolute;
        height: 20px;
        width: 20px;
        margin-top: 7px;
      }

      i.app-circle-menu {
        position: relative;
        min-width: 10px;
        height: 12px;
        display: inline-block;

        &:before{
          content: '';
          display: block;
          top: 9px;
          width: 10px;
          height: 10px;
          border-style: solid;
          border-width: 3px;
          border-radius: 100%;
        }
      }
    }
  }
}

// Menu colors for different modules

body.mod-symap #menu .sections > ul > li > a i:before { color: @SYMAPLightBlue }
body.mod-dimap #menu .sections > ul > li > a i:before { color: @SYMAPOrange }
body.mod-pk #menu .sections > ul > li > a i:before { color: @SYMAPGreen }
body.mod-settings #menu .sections > ul > li > a i:before { color: @SYMAPGray }
body.mod-dashboard #menu .sections > ul > li > a i:before { color: @SYMAPLightBlue }
body.mod-vfze #menu .sections > ul > li > a i:before { color: @SYMAPLightBlue }

body.mod-symap #menu .sections > ul > li:hover > a,
body.mod-symap #menu .sections > ul > li.active > a,
body.mod-symap #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPLightBlue }

body.mod-dimap #menu .sections > ul > li:hover > a,
body.mod-dimap #menu .sections > ul > li.active > a,
body.mod-dimap #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPOrange }

body.mod-pk #menu .sections > ul > li:hover > a,
body.mod-pk #menu .sections > ul > li.active > a,
body.mod-pk #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPGreen }

body.mod-settings #menu .sections > ul > li:hover > a,
body.mod-settings #menu .sections > ul > li.active > a,
body.mod-settings #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPGray }

body.mod-dashboard #menu .sections > ul > li:hover > a,
body.mod-dashboard #menu .sections > ul > li.active > a,
body.mod-dashboard #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPLightBlue }

body.mod-vfze #menu .sections > ul > li:hover > a,
body.mod-vfze #menu .sections > ul > li.active > a,
body.mod-vfze #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPLightBlue }

body.mod-landing #menu .sections > ul > li:hover > a,
body.mod-landing #menu .sections > ul > li.active > a,
body.mod-landing #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPLightBlue }

body.mod-sv #menu .sections > ul > li:hover > a,
body.mod-sv #menu .sections > ul > li.active > a,
body.mod-sv #menu .sections > ul > li.submenu-active > a
{ background-color: @SYMAPLightBlue }

// Menu dimensions for different modules
body.mod-dimap #menu { flex: 0 0 230px; }
body.mod-dimap #menu.collapsed { flex: 0 0 50px; }

body.mod-landing #menu { flex: 0 0 200px; }
body.mod-landing #menu.collapsed { flex: 0 0 50px; }

body.mod-dashboard #menu { flex: 0 0 200px; }
body.mod-dashboard #menu.collapsed { flex: 0 0 50px; }

@media print {
  div#menu {
    display: none;
  }
}
